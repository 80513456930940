<template>
  <div>
    <div class="photo">
      <img class="img" :src="cover" />
    </div>
    <div class="mask" v-if="hasLyrics" />
    <div class="player">
      <aplayer
        autoplay
        v-if="showMusic"
        :music="{
			title: this.title,
			artist: this.artist,
			src: this.url,
			pic: this.avatar
		}"
      />
    </div>
    <van-list class="list" v-model="loading" :finished="finished" @load="onLoad">
      <van-cell v-for="item in list" :key="item" :title="item" class="listcell" />
    </van-list>
    <div class="loading" v-show="loading">
      <van-loading color="#c51a04" size="34px">请求数据中...</van-loading>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueAplayer from "vue-aplayer";
import { List, Cell, Loading } from "vant";
Vue.use(Loading);
export default {
  components: {
    aplayer: VueAplayer,
    [List.name]: List,
    [Cell.name]: Cell
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: true,
      showMusic: false,
      flag: false,
      musicList: "",
      songList: [
        {
          title: "",
          author: "",
          url: "",
          pic: "",
          lrc: ""
        }
      ],
      url: "",
      avatar: "",
      photo: "",
      title: "",
      artist: "",
      cover: "",
      objectId: "",
      hasLyrics: false
    };
  },
  mounted() {
    if (this.$route.query.id != undefined) {
      this.objectId = this.$route.query.id;
    } else if (this.$route.params.id != undefined) {
      this.objectId = this.$route.params.id;
    }

    if (this.objectId == undefined || this.objectId == "") {
      this.objectId = "GjN3O77y";
    }
    this.fetchOperaInfo();
  },
  methods: {
    fetchOperaInfo() {
      this.loading = true;

      const query = Bmob.Query("Opera");
      query
        .get(this.objectId)
        .then(res => {
          this.loading = false;
          console.log(res);
          this.url = res.url;
          this.title = res.title;
          this.avatar = res.avatar;
          this.artist = res.artist;
          this.cover = res.headerPic;
          if (res.lyricString != undefined) {
            this.list = res.lyricString.split(",");
          }
          if (res.list == undefined || res.list.length == 0) {
            this.hasLyrics = false;
          } else {
            this.hasLyrics = true;
          }
          this.showMusic = true;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      //   setTimeout(() => {
      //     for (let i = 0; i < 10; i++) {
      //       this.list.push(this.list.length + 1);
      //     }
      //     // 加载状态结束
      //     this.loading = false;
      //     // 数据全部加载完成
      //     if (this.list.length >= 40) {
      //       this.finished = true;
      //     }
      //   }, 1000);
    }
  }
};
</script>

<style scoped lang="stylus">
.photo {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-size: 100% 100%;
}

.photo .img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.player {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
}

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.list {
  width: 100%;
  height: 100%;
  position:sticky;
  top: 60px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: #3336;
}

.listcell {
  margin: 20px;
  color: white;
  textsize: 30;
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(250, 245, 235, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
